
 
import React from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  validateSliderUrl,
  sliderDesktopImages,
  sliderMobileImages,
  sliderPost,
  sliderDelete,
  sliderUpdate,
  updateSliderMobileImage,
  updateSliderDeskImage,
} from "../../../redux/slider/sliderSlice";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";

const UpdateSlider = () => {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const {
    mobileimage,
    desktopimage,
    mobileimageLoading,
    desktopimageLoading,
    slidertotal,
  } = useSelector((store) => store.Sliders);
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");
  const [sliderId, setSliderId] = useState("");

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(sliderDesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const mobileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(sliderMobileImages({ mobileImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const dispatch = useDispatch();
    const params = useParams();
    useEffect(() => {
      const sliderUrl = params.url;
      const updateslider = slidertotal.find((slide) => slide._id === sliderUrl);
      setSliderId(updateslider._id);
      setName(updateslider.name);
      setUrl(updateslider.URL);
      setShow(updateslider.show);
      setCategory(updateslider.Category);
      setCategoryid(updateslider.CatId);
      dispatch(updateSliderMobileImage(updateslider.MblImg));
      dispatch(updateSliderDeskImage(updateslider.DeskImg));
    }, [params.url, slidertotal]);
    

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const sliderurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(sliderurl);

    if (sliderurl !== "") {
      let responce = await dispatch(validateSliderUrl(sliderurl));
      if (responce.payload.success) {
        setError("Slider already exist");
        setErrorcolor("red");
      } else if (sliderurl.length <= 2) {
        setErrorcolor("red");
        setError("minimum 3 letters");
        setCheckurl("");
      } else if (sliderurl.length >= 3) {
        setErrorcolor(" ");
        setError("");
        setCheckurl(sliderurl);
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        URL: url,
        Category: category,
        CatId: categoryid,
        DeskImg: mobileimage,
        MblImg: desktopimage,
        show: show,
        sliderid: sliderId,
      };
      console.log(formData);
      const data = await dispatch(sliderUpdate(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Slider Updated");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
  };


  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Slider</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Slider</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            selected={data._id === categoryid}
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Slider Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => verifyurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    {/* <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url *</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={url}
                        required
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </Col> */}
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 1500px * 470px
                      </p>
                      <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label>Mobile Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={mobileChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 500px * 226px
                      </p>
                      <div>
                        {mobileimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={mobileimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Slider
                  </button>
                </div>
                {/* <div className="card-footer ">
                  {checkurl !== "" &&
                  categoryid !== "" &&
                  !mobileimageLoading &&
                  !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Slider
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Slider
                    </button>
                  )}
                </div> */}
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateSlider;
