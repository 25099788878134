import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";

import {
  groceryPost,
  groceryImages,
  validateGrocerySlugUrl,
} from "../../../redux/grocery/grocerySlice";

const AddGrocery = () => {
  const { superCatTotal } = useSelector((store) => store.superCategory);
  const { universaltag } = useSelector((store) => store.Tags);
  const { categorytag } = useSelector((store) => store.Tags);
  const { bannertag } = useSelector((store) => store.Tags);
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { subcategorytotal } = useSelector((store) => store.subCategories);
  const { brandtotal } = useSelector((store) => store.brand);
  const {
    mobileimage,
    desktopimage,
    mobileimageicon,
    desktopimageicon,
    isgrocerydeskimageLoading,
  } = useSelector((store) => store.groceries);

  const [productname, setProductname] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [cattag, setCattag] = useState([]);
  const [categorytags, setCategorytags] = useState([]);
  const [unitag, setUnitag] = useState([]);
  const [universaltags, setUniversaltags] = useState([]);

  const [bantag, setBantag] = useState([]);
  const [bannertags, setBannertags] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [brand, setBrand] = useState("");
  const [catbrand, setCatbrand] = useState([]);
  const [brandbysupcat, setBrandbysupcat] = useState([]);
  const [slugUrl, setSlugUrl] = useState("");
  const [about, setAbout] = useState("");
  const [type, setType] = useState("");
  const [productoption, setProductoption] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [rating, setRating] = useState("");
  const [productinfo, setProductinfo] = useState("");
  const [recommended, setRecommended] = useState(Boolean(0));
  const [multi, setMulti] = useState(Boolean(0));
  const [single, setSingle] = useState(Boolean(0));
  const [cashback, setCashback] = useState(Boolean(0));
  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [pcb1, setPcb1] = useState(1);
  const [pcb2, setPcb2] = useState(2);
  const [pcb3, setPcb3] = useState(3);
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [gstcost, setGstcost] = useState(0);
  const [prime, setPrime] = useState(Boolean(0));
  const [packsizeoutofstock, setPacksizeoutofstock] = useState(Boolean(0));
  const [packsizeautoupdate, setPacksizeautoupdate] = useState(Boolean(0));
  const [error, setError] = useState(false);
  const [checkUrl, setCheckUrl] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const dispatch = useDispatch();

  const supercat = [
    { id: "63d767855658519287238b63", name: "Supermart" },
    { id: "63d8da2bd405398b770b508f", name: "Non-veg" },
    { id: "63d8da4ad405398b770b50aa", name: "Vegetables & fruits" },
  ];

  const selectuniversaltag = (evt) => {
    if (unitag.includes(evt.target.value)) {
      setUnitag(
        unitag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setUnitag((selunitag) => [...selunitag, evt.target.value]);
    }
  };

  const selectbannertag = (evt) => {
    if (bantag.includes(evt.target.value)) {
      setBantag(
        bantag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setBantag((selbantag) => [...selbantag, evt.target.value]);
    }
  };

  const selectcategorytag = (evt) => {
    if (cattag.includes(evt.target.value)) {
      setCattag(
        cattag.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setCattag((selcattag) => [...selcattag, evt.target.value]);
    }
  };

  useEffect(() => {
    const cattags = categorytag.filter((tag) => tag.CatId === categoryid);

    setCategorytags(cattags);
  }, [categoryid]);

  const verifyUrl = async (e) => {
    const value = e.target.value;
    setProductname(value);
    setCheckUrl("");
    const groceryUrl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setSlugUrl(groceryUrl);

    if (groceryUrl !== "") {
      let response = await dispatch(validateGrocerySlugUrl(groceryUrl));
      if (response.payload.success) {
        setError("Grocery Already exist");
        setErrorcolor("red");
      } else {
        setErrorcolor(" ");
        setError("");
        setCheckUrl(groceryUrl);
      }
    }
  };

  // const discount = Math.round(((mrp - costprice) * 100) / mrp);

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (checkUrl !== "") {
      const formData = {
        ItemName:
          productname.charAt(0).toUpperCase() +
          productname.slice(1).toLowerCase(),
        Url: slugUrl,
        superCategory: supercategory,
        superCategoryId: supercategoryid,
        Category: category,
        CatId: categoryid,
        SubCat: subcategory,
        SubCatId: subcategoryid,
        Brand: brand,
        ProductInfo: productinfo,
        About: about,
        Ingredient: ingredient,
        Rating: rating,
        KeyWords: metakeyword,
        Description: metadesc,
        Title: metatitle,
        Type: type,
        Options: productoption,
        Recommends: recommended,
        Multi: multi,
        Single: single,
        Cashback: cashback,
        HotProducts: hotproducts,
        Trending: trending,
        Offers: offers,
        Pcb1: 1,
        Pcb2: 2,
        Pcb3: 3,
        UniversalTag: unitag.join(", "),
        BannerTag: bantag.join(", "),
        CategoryTag: cattag.join(", "),
        PackSizes: options,
      };

      console.log(formData);
      const data = await dispatch(groceryPost(formData));

      if (!data) {
        <div></div>;
      } else {
        if (data.payload.success) {
          alert("Grocery Added");
          setProductname("");
          setSupercategory("");
          setCategory("");
          setBrand("");
          setSubcategory("");
          setSlugUrl("");
          setAbout("");
          setType("");
          setProductoption("");
          setIngredient("");
          setRating("");
          setProductinfo("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
        } else {
          alert(data.message);
          console.log(data.error);
        }
      }
    } else {
      alert("please enter correct values");
    }
  };

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSupercategoryid(value);
    setSupercategory(text);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  };

  useEffect(() => {
    const catbysupercat = categorytotal.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categorytotal]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setSubcategoryid(value);
    setSubcategory(text);
  };
  useEffect(() => {
    const subcatbycat = subcategorytotal.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategorytotal]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  };

  useEffect(() => {
    // const brandbysupcat = brandtotal.filter(
    //   (brand) => brand.superCategoryId === supercategoryid
    // );
    const brandbysupcat = [...brandtotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setBrandbysupcat(brandbysupcat);
  }, [supercategoryid, brandtotal]);

  const imageChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(groceryImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["GstCost"] = gstcost;
    }
    setOptions(temp);
    console.log(options);
  }, [gstcost]);

  // useEffect(() => {
  //   let temp = [...options];
  //   for (let index = 0; index < options.length; index++) {
  //     temp[index]["Pri"] = prime;
  //   }
  //   setOptions(temp);
  //   console.log(options);
  // }, [prime]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["OutOfStack"] = packsizeoutofstock;
    }
    setOptions(temp);
    console.log(options);
  }, [packsizeoutofstock]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["StockAutoUpdate"] = packsizeautoupdate;
    }
    setOptions(temp);
    console.log(options);
  }, [packsizeautoupdate]);

  useEffect(() => {
    let temp = [...options];
    for (let index = 0; index < options.length; index++) {
      temp[index]["ImgUrlMbl"] = mobileimage;
      temp[index]["ImgUrlMblIcon"] = mobileimageicon;
      temp[index]["ImgUrlDesk"] = desktopimage;
      temp[index]["ImgUrlDeskIcon"] = desktopimageicon;
    }
    setOptions(temp);
    console.log(options);
  }, [mobileimage, mobileimageicon, desktopimage, desktopimageicon]);

  const [options, setOptions] = useState([
    {
      PackSize: "",
      ImgUrlMbl: mobileimage,
      ImgUrlMblIcon: mobileimageicon,
      ImgUrlDesk: desktopimage,
      ImgUrlDeskIcon: desktopimageicon,
      ImgUrlDeskTiles: "",
      CostPrc: 0,
      GstCost: gstcost,
      SellingPrice: 0,
      GstSellPrc: 0,
      Mrp: 0,
      OutOfStack: packsizeoutofstock,
      Pri: true,
      StockAutoUpdate: packsizeautoupdate,
      Discount: 0,
    },
  ]);

  const handleCheck = (index, e, selected) => {
    console.log(index, e.target.value, selected);
    let temp = [...options];
    temp[index][selected] = e.target.value;
    setOptions(temp);
    if (
      (selected === "Mrp" || selected === "SellingPrice") &&
      temp[index]["Mrp"] > 0 &&
      temp[index]["SellingPrice"] > 0 &&
      temp[index]["Mrp"] > temp[index]["SellingPrice"]
    ) {
      handleDiscount(index, e, selected);
    }
  };
  const handleDiscount = (index, e, selected) => {
    let temp = [...options];
    const Discount = Math.round(
      ((temp[index]["Mrp"] - temp[index]["SellingPrice"]) * 100) /
        temp[index]["Mrp"]
    );
    console.log(Discount);
    console.log(index);
    temp[index]["Discount"] = Discount;

    setOptions(temp);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        PackSize: "",
        ImgUrlDeskTiles: "",
        ImgUrlMbl: mobileimage,
        ImgUrlMblIcon: mobileimageicon,
        ImgUrlDesk: desktopimage,
        ImgUrlDeskIcon: desktopimageicon,
        CostPrc: 0,
        GstCost: gstcost,
        SellingPrice: 0,
        GstSellPrc: 0,
        Mrp: 0,
        OutOfStack: packsizeoutofstock,
        Pri: false,
        StockAutoUpdate: packsizeautoupdate,
        Discount: 0,
      },
    ]);
  };

  const removeRowClick = (index) => {
    const optionList = [...options];
    optionList.splice(index, 1);
    setOptions(optionList);
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Grocery</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Super Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectsupCategory(e);
                        }}
                        value={supercat.id}
                        name={supercat.name}
                      >
                        <option value="0">{"Select Super Category"}</option>
                        {supercat.map((data) => (
                          <option
                            key={data.id}
                            value={data.id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={catbysuper._id}
                        name={catbysuper.name}
                      >
                        <option value="0">{"Select Category"}</option>
                        {catbysuper.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Sub Category
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectSubCategory(e);
                        }}
                        value={subCat._id}
                        name={subCat.name}
                      >
                        <option value="0">{"Select Sub Category"}</option>
                        {subCat.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand *
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        value={brandbysupcat.name}
                        name={brandbysupcat.name}
                      >
                        <option value="0">{"Select Brand"}</option>
                        {brandbysupcat.map((data) => (
                          <option
                            key={data.name}
                            value={data.name}
                            name={data.name}
                            required
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  name....."
                        required
                        value={productname}
                        onChange={(e) => verifyUrl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Url*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter name....."
                        required
                        value={slugUrl}
                        onChange={(e) => setSlugUrl(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info....."
                        value={productinfo}
                        onChange={(e) => setProductinfo(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">About</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={about}
                        onChange={(e) => setAbout(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Ingradient*
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter Ingradient....."
                        value={ingredient}
                        onChange={(e) => setIngredient(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Rating
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Rating"
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label> Keywords </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label> Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">Type</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="enter  Product Info....."
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Options
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="about"
                        value={productoption}
                        onChange={(e) => setProductoption(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="recommended"
                        checked={recommended === true}
                        onChange={(e) => {
                          setRecommended(e.target.checked);
                        }}
                      />
                      <Form.Label>Recommends</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="radio"
                        name="radiobtn"
                        value="multi"
                        className="me-1"
                        checked={multi === true}
                        onChange={(e) => {
                          setMulti(e.target.checked);
                        }}
                      />
                      <Form.Label>Multi </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="radio"
                        name="radiobtn"
                        value="single"
                        className="me-1"
                        checked={single === true}
                        onChange={(e) => {
                          setSingle(e.target.checked);
                        }}
                      />
                      <Form.Label>Single </Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="cashback"
                        checked={cashback === true}
                        onChange={(e) => {
                          setCashback(e.target.checked);
                        }}
                      />
                      <Form.Label>Cashback</Form.Label>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="hotproducts"
                        checked={hotproducts === true}
                        onChange={(e) => {
                          setHotproducts(e.target.checked);
                        }}
                      />
                      <Form.Label>Hot Products</Form.Label>
                    </Col>
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="trending"
                        checked={trending === true}
                        onChange={(e) => {
                          setTrending(e.target.checked);
                        }}
                      />
                      <Form.Label>Trending</Form.Label>
                    </Col>

                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="offers"
                        checked={offers === true}
                        onChange={(e) => {
                          setOffers(e.target.checked);
                        }}
                      />
                      <Form.Label>Offer</Form.Label>
                    </Col>
                  </Row>

                  <hr color="black" />
                  <div className="categorytag">
                    <div>
                      <h5>Universal Tags</h5>
                      {universaltag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={unitag.includes(tag.name)}
                              onChange={(e) => {
                                selectuniversaltag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      <h5>Category Tags</h5>
                      {categorytags.map((tag) => {
                        return (
                          <div key={tag._id}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={cattag.includes(tag.name)}
                              onChange={(e) => {
                                selectcategorytag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>

                    <div>
                      <h5>Banner Tags</h5>
                      {bannertag.map((tag, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              value={tag.name}
                              checked={bantag.includes(tag.name)}
                              onChange={(e) => {
                                selectbannertag(e);
                              }}
                            />
                            {tag.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Grocery Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="No File Choosen"
                        onChange={imageChange}
                        multiple
                        required
                      />
                      <p>
                        * The image width and height should be 450px * 450px
                      </p>
                      <div>
                        {isgrocerydeskimageLoading ? (
                          <div></div>
                        ) : (
                          <img src={desktopimage} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                      <Form.Control
                        type="number"
                        value={gstcost}
                        required
                        onChange={(e) => setGstcost(e.target.value)}
                      />
                    </Col>
                  </Row>

                  {/* <Row className="g-2  m-2">
                    <Col>
                      <input
                        type="checkbox"
                        className="me-1"
                        value="prime"
                        checked={prime === true}
                        onChange={(e) => {
                          setPrime(e.target.checked);
                        }}
                      />
                      <Form.Label>Prime</Form.Label>
                    </Col>
                  </Row> */}

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Pack Sizes</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Pack Size
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.PackSize}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "PackSize")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              ImgUrlDeskTiles
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.ImgUrlDeskTiles}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "ImgUrlDeskTiles")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Cost Prcice
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.CostPrc}
                              required
                              onChange={(e) => handleCheck(index, e, "CostPrc")}
                            />
                          </Col>
                          {/* <Col md>
                            <Form.Label htmlFor="gstcost">GST Cost</Form.Label>
                            <Form.Control
                              type="number"
                              value={details?.GstCost}
                              required
                              onChange={(e) => handleCheck(index, e, "GstCost")}
                            />
                          </Col> */}
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Sell Price
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.SellingPrice}
                              onChange={(e) =>
                                handleCheck(index, e, "SellingPrice")
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              GST Sell P
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              minLength={2}
                              maxLength={2}
                              value={details?.GstSellPrc}
                              onChange={(e) =>
                                handleCheck(index, e, "GstSellPrc")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              MRP
                            </Form.Label>
                            <Form.Control
                              type="number"
                              required
                              value={details?.Mrp}
                              onChange={(e) => handleCheck(index, e, "Mrp")}
                            />
                          </Col>
                          <Col style={{ marginTop: "45px" }}>
                            <input
                              type="checkbox"
                              className="me-1"
                              value="packsizeoutofstock"
                              checked={packsizeoutofstock === true}
                              onChange={(e) => {
                                setPacksizeoutofstock(e.target.checked);
                              }}
                            />
                            <Form.Label>OutOfStack</Form.Label>
                          </Col>
                          <Col style={{ marginTop: "45px" }}>
                            <input
                              type="checkbox"
                              className="me-1"
                              value="packsizeautoupdate"
                              checked={packsizeautoupdate === true}
                              onChange={(e) => {
                                setPacksizeautoupdate(e.target.checked);
                              }}
                            />
                            <Form.Label>StockAutoUpdate</Form.Label>
                          </Col>

                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={handleNewRow}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className="card-footer text-center">
                  {checkUrl !== "" &&
                  subcategoryid !== "" &&
                  categoryid !== "" &&
                  !isgrocerydeskimageLoading ? (
                    <button className="btn btn-danger" type="submit">
                      Add Grocery
                    </button>
                  ) : (
                    <button className="btn btn-danger" disabled type="submit">
                      Add Grocery
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddGrocery;
