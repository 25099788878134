import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { addressupdate } from "../redux/Athentication/AuthSlice";

const AddressBulkUploade = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);

    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    for (let i in data) {
      let myrow = data[i];

      const formData = {
        clientId: myrow.clientid,
        AName: String(myrow.Aname),
        Number: String(myrow.Number),
        Pincode: Number(myrow.Pincode),
        State: "Jharkhand",
        City: String(myrow.City),
        HNo: String(myrow.HNo),
        StreetDet: String(myrow.StreetDet),
        LandMark: String(myrow.LandMark),
        AreaDet: String(myrow.AreaDet),
        Address: String(myrow.Address),
        Type: String(myrow.Type),
        prime: Boolean(myrow.prime),
        Mobile: String(myrow.Number),
        Tag: String(myrow.Tag),
      };
      try {
        const clientuploade = await dispatch(addressupdate(formData));
        console.log(clientuploade, "clientuploade");
        if (clientuploade.payload.success) {
          setUploadedProductName(` ${myrow.Number}  upload successfull`);
        }
      } catch (error) {}
    }
  };
  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Address Bulk Upload</h5>
            </div>
            <div className="card-body">
              <h6 className="ps-3">Address Excel File *</h6>
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput"></Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Address Bulk Upload
                  </button>
                </Col>
              </Row>
              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressBulkUploade;
