import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {  useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pinAmountUpdate } from "../../../redux/pinAmount/pinAmountSlice";
import { useEffect } from "react";

const UpdatePinAmount = () => {
  const { pinamounttotal } = useSelector((store) => store.Pinamounts);
  const [pincode, setPincode] = useState(123456);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pinamountId, setPinamountId] = useState("");
  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setPincode(numbers);
    };
    
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        const pinId = params.pinamtid;
        const UpdatePinAmt = pinamounttotal.find((pinamount) => pinamount._id === pinId);

        setPinamountId(UpdatePinAmt._id);
        setPincode(UpdatePinAmt.pin);
        setArea(UpdatePinAmt.Area);
        setCity(UpdatePinAmt.City);
    }, [params.pinamtid,pinamounttotal])
    
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      pin: pincode,
      Area: area,
      City: city,
      pinamountid: pinamountId,
    };
    console.log(formData);
        const data = await dispatch(pinAmountUpdate(formData));
        if (!data) {
          <div></div>;
        } else {
          if (data.payload.success) {
            alert("Pinamount Updated");
          } else {
            alert(data.message);
            console.log(data.error);
          }
        }
  };


  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Pin-Amount</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Pin-Amount</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        value={pincode}
                        minLength={6}
                        maxLength={6}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Area</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area Name"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter City Name"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Update Pin-Amount
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdatePinAmount;
