import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  pinAmountDelete,
  pinAmountPost,
} from "../../../redux/pinAmount/pinAmountSlice";

const AddPinAmount = () => {
  const { pinamounttotal } = useSelector((store) => store.Pinamounts);
  const [pincode, setPincode] = useState(123456);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setPincode(numbers);
  };
  const dispatch = useDispatch();
  const handlesubmit = async (e) => {
    e.preventDefault();
    const formData = {
      pin: pincode,
      Area: area,
      City: city,
    };
    console.log(formData);
    const data = await dispatch(pinAmountPost(formData));
            if (!data) {
              <div></div>;
            } else {
              if (data.payload.success) {
                alert("Pinamount Added");
                setPincode("");
                setArea("");
                setCity("");
              } else {
                alert(data.message);
                console.log(data.error);
              }
            }
  };

  const deleteClick = async (e, id) => {
    dispatch(pinAmountDelete(id));
  };
  const columns = [
    {
      field: "id",
      headerName: "S. No",
      width: 100,
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "pin",
      headerName: "PINCODE",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Area",
      headerName: "AREA",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "City",
      headerName: "CITY",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ACTION",
      width: 160,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatepinamount/${params.row._id}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 160,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Pin-Amount</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Pincode"
                        value={pincode}
                        minLength={6}
                        maxLength={6}
                        onChange={(e) => handleChange(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Area</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Area Name"
                        value={area}
                        onChange={(e) => setArea(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter City Name"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  <button className="btn btn-primary" type="submit">
                    Add Pin-Amount
                  </button>
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div className="card-footer">
                <h5>List</h5>
              </div>
              <div className="card-body">
                <div
                  style={{ height: 650, width: "100%" }}
                  className="d-flex m-auto text-align-center"
                >
                  <DataGrid
                    rows={pinamounttotal}
                    columns={columns}
                    getRowId={(row) => row._id}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddPinAmount;
