import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  slidertotal: localStorage.getItem("slidertotal")
    ? JSON.parse(localStorage.getItem("slidertotal"))
    : [],
  mobileimage: "",
  desktopimage: "",
  mobileimageLoading: true,
  desktopimageLoading: true,
  isLoading: true,
  sliderLoading: true,
  delsliderLoading: true,
};

  export const getSlider = createAsyncThunk(
    "Slider/getSlider",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/slider/all`;
        const resp = await axios(url);
        return resp.data.sliders;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
);
  
export const sliderPost = createAsyncThunk(
  "slider/sliderPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
       
      };
      const url = `${Baseurl}/api/v1/slider/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("slider Not create");
    }
  }
);

export const validateSliderUrl = createAsyncThunk(
   "SliderUrl/validateSliderUrl",
   async (url, thunkAPI) => {
     let resp = {
       success: false,
       message: "new email",
     };
     try {
       const url = `${Baseurl}/api/v1/slider/url/${url}`;
       const resp = await axios.get(url);
       return resp.data;
     } catch (error) {
       return error;
     }
   }
);
 
export const sliderMobileImages = createAsyncThunk(
  "slider/sliderMobileImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/slider/slidermobileimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("slidermobileimages Not create");
    }
  }
);

export const sliderDesktopImages = createAsyncThunk(
  "slider/sliderDesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/slider/sliderdeskimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("sliderdeskimages Not create");
    }
  }
);

  export const sliderUpdate = createAsyncThunk(
    "slider/sliderUpdate",
    async (formData, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
          // headers: {
          //   "Content-Type":
          //     "multipart/form-data; boundary=AaB03x" +
          //     "--AaB03x" +
          //     "Content-Disposition: file" +
          //     "Content-Type: png/jpg/jpeg" +
          //     "Content-Transfer-Encoding: binary" +
          //     "...data... " +
          //     "--AaB03x--",
          //   "Accept": "application/json",
          //   "type": "formData",
          // },
        };
        const url = `${Baseurl}/api/v1/slider/${formData.sliderid}`;
        const resp = await axios.put(url, formData, config);
        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue("slider Not create");
      }
    }
);
  
  export const sliderDelete = createAsyncThunk(
    "slider/sliderDelete",
    async (id, thunkAPI) => {
      try {
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/slider/${id}`;
        const resp = await axios.delete(url, id, config);
        console.log(resp.data);
        const myreturn = {
          success: resp.data.success,
          id: id,
        };
        return myreturn;
      } catch (error) {
        return thunkAPI.rejectWithValue("slider Not create");
      }
    }
);
  
const SliderSlice = createSlice({
  name: "Sliders",
  initialState,
  reducers: {
    updateSliderMobileImage(state, action) {
      state.mobileimage = action.payload;
      state.mobileimageLoading = false;
    },
    updateSliderDeskImage(state, action) {
      state.desktopimage = action.payload;
      state.desktopimageLoading = false;
    },
  },
  extraReducers: {
    [getSlider.pending]: (state) => {
      state.isLoading = true;
    },
    [getSlider.fulfilled]: (state, action) => {
      state.slidertotal = action.payload;

        state.isLoading = false;
        state.sliderLoading = false;
        localStorage.setItem("slidertotal", JSON.stringify(state.slidertotal));
    },
    [getSlider.rejected]: (state, action) => {
      state.isLoading = true;
      },
    
    
    [sliderPost.pending]: (state) => {
      state.sliderLoading = true;
    },

    [sliderPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.slidertotal = [...state.slidertotal, action.payload.slider];
        localStorage.setItem("slidertotal", JSON.stringify(state.slidertotal));
      }
      state.sliderLoading = false;
    },
    [sliderPost.rejected]: (state, action) => {
      state.sliderLoading = true;
      },
    

    [sliderMobileImages.pending]: (state) => {
      state.mobileimageLoading = true;
    },
    [sliderMobileImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.mobileimage = action.payload.mobileImages;
      }
      state.mobileimageLoading = false;
    },
    [sliderMobileImages.rejected]: (state, action) => {
      state.mobileimageLoading = true;
      },
    
    
    [sliderDesktopImages.pending]: (state) => {
      state.desktopimageLoading = true;
    },
    [sliderDesktopImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.desktopimageLoading = false;
    },
    [sliderDesktopImages.rejected]: (state, action) => {
      state.desktopimageLoading = true;
      },
    
    
    [sliderUpdate.pending]: (state) => {
      state.sliderLoading = true;
    },
    [sliderUpdate.fulfilled]: (state, action) => {
      if (action.payload.success) {state.slidertotal = state.slidertotal.filter(
        (slider) => slider._id !== action.payload.slider._id);
        state.slidertotal = [...state.slidertotal, action.payload.slider];
        state.mobileimage = "";
        state.desktopimage = "";
        state.mobileimageLoading = true;
        state.desktopimageLoading = true;
        localStorage.setItem("slidertotal", JSON.stringify(state.slidertotal));
      }
      state.sliderLoading = false;
    },

    [sliderUpdate.rejected]: (state, action) => {
      state.sliderLoading = true;
    },




    [sliderDelete.pending]: (state) => {
      state.delsliderLoading = true;
    },

    [sliderDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.slidertotal = state.slidertotal
          .filter((slider) => slider._id !== action.payload.id);
        localStorage.setItem("slidertotal", JSON.stringify(state.slidertotal));
      }
      state.delsliderLoading = false;
    },

    [sliderDelete.rejected]: (state, action) => {
      state.delsliderLoading = true;
    },
  },
});

export const { updateSliderMobileImage, updateSliderDeskImage } = SliderSlice.actions;
export default SliderSlice.reducer;
